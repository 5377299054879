import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  message,
  Input,
  Button,
  Form,
  Space,
  Tooltip,
  Upload,
  Image,
} from "antd";
import "./style.css";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  deleteCategory,
  getPagingCategory,
  insertCategory,
  updateCategory,
} from "../../helpers/helper";
import moment from "moment";
import { Drawer, Modal } from "antd";
import toSlug from "../../common/function";
import Editor, { UploadService } from "../../Components/Common/Editor";
import DataTable from "../../common/DataTable";
import { v4 as uuidv4 } from "uuid";

const Categories = () => {
  document.title = "Management Categories";

  const [form] = Form.useForm();
  const [listCategory, setListCategory] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [indexPage, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const editorContentRef = useRef("");
  const nameRefId = useRef("common");
  const refId = useRef(uuidv4());
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      setListCategory(dataRes);
    }
    fetchData();
  }, []);

  const getAllData = async (_prams, indexPage = 1) => {
    try {
      const params = _prams
        ? _prams
        : {
            pageIndex: indexPage,
            pageSize: pageSize,
            search: "",
            content: true,
          };
      const dataRes = await getPagingCategory(params);
      setTotalPage(dataRes.totalPages);
      if (!dataRes) {
        return false;
      }
      const data = convertDataTable(dataRes.data);
      setLoading(false);

      data.sort(compare);
      return dataRes?.data ? data : [];
    } catch (error) {
      message.error("Bạn không có quyền lấy tất cả thông tin hoạt động!");
      setTotalPage(0);
      return [];
    }
    function compare(a, b) {
      if (b.type) {
        return a.type === b.type ? 1 : 0;
      } else {
        return -1;
      }
    }
  };

  const convertDataTable = (dataArray) => {
    const data =
      dataArray?.length > 0 &&
      dataArray?.map((item) => {
        return {
          key: item._id,
          categoryName: item.categoryName,
          categorySlug: item.categorySlug,
          categoryOrder: item.categoryOrder,
          type: item.type,
          description: item.description,
          isShow: item.isShow,
          content: item.content,
          canonical: item.canonical,
          faq: item.faq,
          seo_title: item.seo_title,
          thumb: item.thumb,
          createdTime: moment(new Date(item.createdTime)).format("DD/MM/YYYY"),
        };
      });
    return dataArray ? data : [];
  };

  const onPageChange = (page, page_size) => {
    setPageIndex(page);
    setPageSize(page_size);
    onPageChangeAtSearch(page, page_size);
  };

  const onPageChangeAtSearch = async (indexPage, page_size) => {
    const params = {
      pageIndex: indexPage,
      pageSize: page_size,
    };

    const dataRes = await getAllData(params, indexPage);
    setListCategory(dataRes);
  };

  const handleChange = async ({ fileList: newFileList }) => {
    if (!newFileList[0]?.originFileObj) return;
    var formData = new FormData();
    formData.append("refId", refId.current);
    formData.append("file", newFileList[0].originFileObj);
    await UploadService(formData).then((res) => {
      setFileList(newFileList);
      setPreviewTitle(refId.current + "/" + res.url);
    });
  };

  const onFinish = async (data) => {
    const dataReq = {
      categoryName: data.categoryName,
      categorySlug: data.categorySlug,
      categoryOrder: data.categoryOrder,
      isShow: isShow,
      thumb: previewTitle,
      type: data.type,
      content: editorContentRef.current,
      canonical: data.canonical,
      faq: data.faq,
      seo_title: data.seo_title,
      description: descriptionData,
    };
    if (!data.id) {
      //Save
      const dataRes = await insertCategory(dataReq);
      if (dataRes.status === 1) {
        message.success(`Lưu thành công!`);
        setVisibleForm(false);
        handleCloseDrawer();
      } else {
        message.error(`Lưu thất bại!`);
      }
    } else {
      //Update

      const dataRes = await updateCategory(data.id, dataReq);
      if (dataRes.status === 1) {
        message.success(`Lưu thành công!`);
        handleCloseDrawer();
      } else {
        message.error(`Lưu thất bại!`);
      }
    }
    const dataRes = await getAllData();
    setListCategory(dataRes);
    form.resetFields();
  };

  const handleChangeTitle = (value) => {
    form.setFieldsValue({
      categorySlug: toSlug(value),
    });
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleRefresh = async () => {
    const dataRes = await getAllData({ pageIndex: 1 });
    setListCategory(dataRes);
    setPageIndex(1);
    form.resetFields();
    setDescriptionData("");
    setFileList([]);
    setPreviewImage("");
  };

  const onEdit = async (key) => {
    const dataEdit = listCategory.filter((item) => item.key === key);

    setIsShow(dataEdit[0].isShow);
    form.setFieldsValue({
      categoryName: dataEdit[0].categoryName,
      categorySlug: dataEdit[0].categorySlug,
      categoryOrder: dataEdit[0].categoryOrder,
      type: dataEdit[0].type,
      thumb: dataEdit[0]?.thumb ? dataEdit[0]?.thumb : "",
      id: dataEdit[0].key,
      isShow: dataEdit[0].isShow,
      canonical: dataEdit[0].canonical,
      faq: dataEdit[0].faq,
      seo_title: dataEdit[0].seo_title,
    });

    if (dataEdit[0]?.thumb) {
      setFileList([
        {
          url: `${process.env.REACT_APP_IMAGE_URL}/${dataEdit[0]?.thumb}`,
          name: dataEdit[0]?.thumb,
        },
      ]);
      setPreviewImage(
        `${process.env.REACT_APP_IMAGE_URL}/${dataEdit[0]?.thumb}`
      );
      setPreviewTitle(dataEdit[0]?.thumb);
    } else {
      setFileList([]);
      setPreviewImage("");
      setPreviewTitle("");
    }

    setDescriptionData(
      dataEdit[0]?.description ? dataEdit[0]?.description : ""
    );
    setContent(dataEdit[0].content || "");
    editorContentRef.current = dataEdit[0].content;
    setDrawerTitle("Sửa danh mục");
    showDrawer();
  };

  const onDelete = async (key) => {
    if (window.confirm("Bạn có chắc muốn xóa không?")) {
      const dataRes = await deleteCategory(key);
      dataRes.status === 1
        ? message.success(`Xóa thành công!`)
        : message.error(`Xóa thất bại!`);

      handleRefresh();
    }
  };

  const handleNewCategory = () => {
    setContent("");
    setDrawerTitle("Thêm Danh mục");
    showDrawer();
    form.resetFields();
  };

  const onClose = () => {
    setContent(null);
    setVisibleForm(false);
  };

  const columns = [
    {
      title: "Tên danh mục",
      dataIndex: "categoryName",
    },
    {
      title: "Slug",
      dataIndex: "categorySlug",
    },
    {
      title: "Thứ tự sắp xếp",
      dataIndex: "categoryOrder",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
    },
    {
      title: "Hình ảnh",
      dataIndex: "thumb",
      render: (_, record) => {
        if (record.thumb) {
          return (
            <Image
              width={150}
              src={`${process.env.REACT_APP_IMAGE_URL}/` + _}
            />
          );
        }
      },
    },
    {
      title: "Loại danh mục",
      dataIndex: "type",
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listCategory.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.key)}
              />
            </Tooltip>
            <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  const showDrawer = () => {
    setVisibleForm(true);
  };

  const handleCloseDrawer = () => {
    setVisibleForm(false);
    form.resetFields();
  };

  const propsUpload = {
    onRemove: async (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setPreviewImage("");
    },
    method: "GET",
    beforeUpload: async (file) => {
      setFileList([file]);
      return;
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Danh mục" pageTitle="Quản lí danh mục" />

          <div>
            <Drawer
              title={drawerTitle}
              placement={"right"}
              width={"70%"}
              onClose={onClose}
              open={visibleForm}
              bodyStyle={{
                paddingBottom: 80,
              }}
              style={{ marginTop: "70px" }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row>
                  <Col hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    name="categoryName"
                    label="Tên danh mục"
                    rules={[
                      {
                        required: true,
                        message: "Please input category  name!",
                      },
                      {
                        type: "categoryName",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter name"
                      name="categoryName"
                      allowClear={true}
                      onChange={(e) => handleChangeTitle(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="categorySlug"
                    label="Slug danh mục"
                    rules={[
                      {
                        required: true,
                        message: "Please input category slug!",
                      },
                      {
                        type: "categorySlug",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter post slug!"
                      name="categorySlug"
                      allowClear={true}
                    />
                  </Form.Item>

                  <Form.Item name="categoryOrder" label="Thứ tự sắp xếp">
                    <Input
                      placeholder="Enter number of category order"
                      name="categoryOrder"
                      allowClear={true}
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item name="type" label="Loại danh mục">
                    <Input
                      placeholder="Enter string of category type"
                      name="type"
                      allowClear={true}
                      type="string"
                    />
                  </Form.Item>
                  <Form.Item name="seo_title" label="SEO Title">
                    <Input
                      placeholder="Enter SEO Title"
                      name="seo_title"
                      allowClear={true}
                      onChange={(e) => {
                        form.setFieldsValue({
                          seo_title: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="canonical" label="Thẻ canonical">
                    <Input
                      placeholder="Enter canonical"
                      name="canonical"
                      allowClear={true}
                      onChange={(e) => {
                        form.setFieldsValue({
                          canonical: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="thumb"
                    label="Hình ảnh"
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn ảnh bài viết!",
                      },
                    ]}
                  >
                    <Space align="start">
                      <Upload
                        {...propsUpload}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                      >
                        {fileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Tải lên
                            </div>
                          </div>
                        )}
                      </Upload>
                      {previewImage && (
                        <>
                          <Modal
                            open={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt={previewTitle}
                              style={{ width: "100%" }}
                              src={previewImage}
                            />
                          </Modal>
                        </>
                      )}
                    </Space>
                  </Form.Item>
                  <Form.Item name="faq" label="FAQ (json)">
                    <Input
                      placeholder="Enter FAQ"
                      name="faq"
                      allowClear={true}
                      onChange={(e) => {
                        form.setFieldsValue({
                          faq: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <div className="ant-col ant-form-item-label">
                    <label
                      htmlFor="content"
                      className="ant-form-item-required"
                      title="Post Content"
                    >
                      Mô tả
                    </label>
                  </div>
                  <textarea
                    value={descriptionData}
                    onChange={(e) => setDescriptionData(e.target.value)}
                    className="form-control"
                    id="Input3"
                    rows="3"
                  ></textarea>
                  {content != null && (
                    <Form.Item label="Nội dung">
                      <Editor
                        value={content}
                        onChange={(v) => (editorContentRef.current = v)}
                        refId={nameRefId}
                      />
                    </Form.Item>
                  )}
                </Row>
                <Form.Item className="mt-3">
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Lưu
                    </Button>
                    <Button
                      type="info"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                      Làm mới trang
                    </Button>
                    <Button type="danger" onClick={handleCloseDrawer}>
                      Đóng
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Drawer>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Col hidden={true}>
                  <Form.Item name="id" label="Id">
                    <Input name="id" />
                  </Form.Item>
                </Col>
                <Form.Item className="mt-3">
                  <Space>
                    <Button type="primary" onClick={handleNewCategory}>
                      Tạo mới
                    </Button>

                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                      Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <DataTable
            listData={listCategory}
            pageSize={pageSize}
            columns={columns}
            indexPage={indexPage}
            totalPage={totalPage}
            onPageChange={onPageChange}
            loading={loading}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Categories;

import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import { Input, Button, Form, message, Space, Tooltip, Drawer } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import {
  insertTags,
  getPagingTags,
  updateTags,
  deleteTags,
} from "../../helpers/helper";
import toSlug from "../../common/function";
import DataTable from "../../common/DataTable";

const getAllData = async (_prams) => {
  const params = _prams
    ? _prams
    : {
        pageIndex: 1,
        pageSize: 10,
        search: "",
      };
  const dataRes = await getPagingTags(params);

  const data =
    dataRes?.data &&
    dataRes?.data.length > 0 &&
    dataRes?.data.map((item) => {
      return {
        key: item._id,
        tagName: item.tagName,
        tagSlug: item.tagSlug,
      };
    });
  return dataRes?.data ? data : [];
};

const Tags = () => {
  document.title = "Management Tags";

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [tagName, setTagName] = useState("");
  const [listTag, setListTag] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [indexPage, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  const getAllData = async (_prams, indexPage = 1) => {
    try {
      const params = _prams
        ? _prams
        : {
            pageIndex: indexPage,
            pageSize: pageSize,
            search: "",
          };
      const dataRes = await getPagingTags(params);
      setTotalPage(dataRes.totalPages);

      if (!dataRes) {
        return false;
      }
      const data = convertDataTable(dataRes.data);
      setLoading(false);
      return dataRes?.data ? data : false;
    } catch (error) {
      message.error("Lấy danh sách thất bại");
      setTotalPage(0);
      return [];
    }
  };

  const convertDataTable = (dataArray) => {
    const data =
      dataArray.length > 0 &&
      dataArray.map((item) => {
        return {
          key: item._id,
          tagName: item.tagName,
          tagSlug: item.tagSlug,
        };
      });
    return dataArray ? data : [];
  };

  const onPageChange = (page, page_size) => {
    setPageIndex(page);
    setPageSize(page_size);
    onPageChangeAtSearch(page, page_size);
  };

  const onPageChangeAtSearch = async (indexPage, page_size) => {
    const params = {
      pageIndex: indexPage,
      pageSize: page_size,
      ...getFormSearch(),
    };

    const dataRes = await getAllData(params, indexPage);
    setListTag(dataRes);
  };

  const getFormSearch = () => {
    const dataForm = formSearch.getFieldsValue();

    return {
      tagNameSearch: dataForm.tagNameSearch || "",
    };
  };

  async function fetchData() {
    const dataRes = await getAllData();
    setListTag(dataRes);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefreshCreate = async () => {
    form.resetFields();
  };

  const onFinish = async (data) => {
    const dataReq = {
      tagName: data.tagName,
      tagSlug: data.tagSlug,
    };

    if (!data.id) {
      //Save
      const dataRes = await insertTags(dataReq);
      dataRes.status === 1
        ? message.success(`Lưu thành công!`)
        : message.error(`Lưu thất bại`);
      if (dataRes.status === 1) {
        onClose();
      }
    } else {
      //Update
      const dataRes = await updateTags(data.id, dataReq);
      dataRes.status === 1
        ? message.success(`Chỉnh sửa thành công`)
        : message.error(`Chỉnh sửa thất bại`);
      if (dataRes.status === 1) {
        onClose();
      }
    }

    form.resetFields();
    const dataRes = await getAllData();
    setListTag(dataRes);
  };

  const handleChangeTitle = (value) => {
    form.setFieldsValue({
      tagSlug: toSlug(value),
    });
  };

  const handleRefresh = async () => {
    form.resetFields();
    const dataRes = await getAllData({ pageIndex: 1 });
    setPageIndex(1);
    setListTag(dataRes);
  };

  const handleSearch = async () => {
    const dataForm = formSearch.getFieldsValue();
    const params = {
      pageIndex: 1,
      pageSize: 10,
      search: dataForm.tagNameSearch ? dataForm.tagNameSearch : "",
    };
    const dataRes = await getAllData(params);
    setListTag(dataRes);
  };

  const onEdit = (key) => {
    const dataEdit = listTag.filter((item) => item.key === key);
    form.setFieldsValue({
      tagName: dataEdit[0].tagName,
      tagSlug: dataEdit[0].tagSlug,
      id: dataEdit[0].key,
    });
    showDrawer();
    setDrawerTitle("Sửa Tag");
  };

  const onClose = () => {
    setVisibleForm(false);
  };

  const showDrawer = () => {
    setVisibleForm(true);
  };

  const handleNewTag = () => {
    setDrawerTitle("Thêm Tag");
    showDrawer();
    form.resetFields();
  };

  const onDelete = async (key) => {
    const dataRes = await deleteTags(key);
    dataRes.status === 1
      ? message.success(`Xóa thành công!`)
      : message.error(`Xóa thất bại!`);

    handleRefresh();
  };

  const columns = [
    {
      title: "Tên Tag",
      dataIndex: "tagName",
    },
    {
      title: "Tag Slug",
      dataIndex: "tagSlug",
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listTag.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.key)}
              />
            </Tooltip>
            <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tag" pageTitle="Quản lí bài viết" />
          <div>
            <Col>
              <Drawer
                title={drawerTitle}
                placement={"right"}
                width={"30%"}
                onClose={onClose}
                open={visibleForm}
                bodyStyle={{
                  paddingBottom: 80,
                }}
                style={{ marginTop: "70px" }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row>
                    <Col sm={4} hidden={true}>
                      <Form.Item name="id" label="Id">
                        <Input name="id" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="tagName"
                        label="Tên Tag"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên tag!",
                          },
                          {
                            type: "tagName",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Nhập tên tag..."
                          name="tagName"
                          allowClear={true}
                          onChange={(e) => handleChangeTitle(e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        name="tagSlug"
                        label="Tag Slug"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tag slug!",
                          },
                          {
                            type: "tagSlug",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Nhập tag slug..."
                          name="tagSlug"
                          allowClear={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Lưu
                      </Button>

                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => handleRefreshCreate()}
                      >
                        Làm mới trang
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Drawer>
            </Col>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={formSearch}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row>
                  <Col sm={4} hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item name="tagNameSearch" label="Tên Tag">
                      <Input
                        placeholder="Nhập tên tag..."
                        name="tagNameSearch"
                        allowClear={true}
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleSearch()}
                    >
                      Tìm kiếm
                    </Button>
                    <Button type="primary" onClick={handleNewTag}>
                      Tạo mới
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                      Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          {/* <div>
            <Table
              columns={columns}
              dataSource={listTag}
              pagination={{ pageSize: 10 }}
            />
          </div> */}
          <DataTable
            listData={listTag}
            pageSize={pageSize}
            columns={columns}
            indexPage={indexPage}
            totalPage={totalPage}
            onPageChange={onPageChange}
            loading={loading}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tags;

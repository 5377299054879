import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import { Button, Space, Table, Tooltip, Form, Drawer, Input, message } from "antd";
import { getAllDomain, createDomain, updateDomain } from '../../helpers/helper';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from "moment";

const Domain = () => {
  document.title = "Management Domain";
  const [listDomain, setListDomain] = useState();
  const [form] = Form.useForm();
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");

  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const res = await getAllDomain();

    if (res.status === 1) {
      setListDomain(convertDataTable(res.data))
    }
  }

  const convertDataTable = (dataArray) => {
    const data =
      dataArray?.length > 0 &&
      dataArray?.map((item) => {
        return {
          key: item._id,
          link: item.link,
          createdTime: moment(new Date(item.createdTime)).format("DD/MM/YYYY"),
        };
      });
    return dataArray ? data : [];
  };

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: "Đường dẫn",
      dataIndex: "link",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdTime",
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listDomain.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.key)}
              />
            </Tooltip>
            {/* <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip> */}
          </Space>
        ) : null,
    },
  ];


  const onClose = () => {
    setVisibleForm(false);
  };
  const showDrawer = () => {
    setVisibleForm(true);
  };

  const handleNewDoamin = () => {
    setDrawerTitle("Thêm Domain");
    showDrawer();
    form.resetFields();
  };

  const onFinish = async (data) => {
    const dataReq = {
      link: data.link
    };

    if (!data.id) {
      //Save
      const dataRes = await createDomain(dataReq);
      dataRes.status === 1
        ? message.success(`Lưu thành công!`)
        : message.error(`Lưu thất bại!`);
      if (dataRes.status === 1) {
        onClose();
      }
    } else {
      //Update
      const dataRes = await updateDomain(data.id, dataReq);
      dataRes.status === 1
        ? message.success(`Cập nhật thành công!`)
        : message.error(`Cập nhật thất bại!`);
      if (dataRes.status === 1) {
        onClose();
      }
    }

    form.resetFields();
    const dataRes = await getAllDomain();
    setListDomain(convertDataTable(dataRes.data));
  };

  const onEdit = (key) => {
    const dataEdit = listDomain.filter((item) => item.key === key);
    form.setFieldsValue({
      id: dataEdit[0].key,
      link: dataEdit[0].link
    });
    setDrawerTitle("Sửa người dùng");
    showDrawer();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Link trang live" pageTitle="Quản lí Quyền" />
          <div>
            <Col>
              <Drawer
                title={drawerTitle}
                placement={"right"}
                width={"50%"}
                onClose={onClose}
                open={visibleForm}
                bodyStyle={{
                  paddingBottom: 80,
                }}
                style={{ marginTop: "70px" }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row>
                    <Col hidden={true}>
                      <Form.Item name="id" label="Id">
                        <Input name="id" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="link"
                        label="Đường dẫn"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập đường dẫn!",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Nhập đường dẫn..."
                          allowClear={true}
                        />
                      </Form.Item>
                    </Col>

                  </Row>
                  <Form.Item className="mt-3">
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Lưu
                      </Button>
                      <Button
                        type="primary"
                        htmlType="button"
                      // onClick={() => handleRefresh()}
                      >
                        Làm mới
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Drawer>
            </Col>
          </div>
          <Row
            style={{
              marginBottom: "10px"
            }}
          >
            <Space>
              <Button
                type="primary"
                onClick={handleNewDoamin}
              >
                Tạo mới
              </Button>
            </Space>
          </Row>
          <Table dataSource={listDomain} columns={columns} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Domain
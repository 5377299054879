/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  Input,
  Button,
  Form,
  message,
  Space,
  Table,
  Tooltip,
  Tag,
  Image,
  Badge,
  Select,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FundViewOutlined,
} from "@ant-design/icons";

import {
  getPagingPostsV2,
  deletePosts,
  getAllCategory,
} from "../../helpers/helper";

import DataTable from "../../common/DataTable";
import { Link } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

const convertHtmlText = (htmlText) => {
  if (htmlText && htmlText.length > 0) {
    let strText =
      new DOMParser().parseFromString(htmlText, "text/html").documentElement
        .textContent || "";
    if (strText && strText.length > 50) {
      strText = strText.slice(0, 50) + "...";
    }
    return strText;
  }
  return "";
};

const Posts = () => {
  document.title = "Management Posts";
  const [formSearch] = Form.useForm();
  const [listPost, setListPost] = useState([]);
  const [postName, setPostName] = useState("");
  const [listCategory, setListCategory] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [indexPage, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  const getAllData = async (_prams, indexPage = 1) => {
    try {
      const params = _prams
        ? _prams
        : {
            pageIndex: indexPage,
            pageSize: pageSize,
            search: "",
          };
      const dataRes = await getPagingPostsV2(params);
      setTotalPage(dataRes.totalPages);
      if (!dataRes) {
        return false;
      }

      const data = convertDataTable(dataRes.data);
      setLoading(false);
      return dataRes?.data ? data : false;
    } catch (error) {
      message.error("Bạn không có quyền lấy tất cả thông tin hoạt động!");
      setTotalPage(0);
      return [];
    }
  };

  const convertDataTable = (dataArray) => {
    const data =
      dataArray.length > 0 &&
      dataArray.map((item) => {
        return {
          key: item._id,
          title: item.title,
          slug: item.slug,
          category: item.category,
          tags: item.tags,
          description: item.description,
          thumb: item.thumb,
          content: item.content,
          status: item.status,
          createdTime: moment(new Date(item.createdTime)).format("DD/MM/YYYY"),
          numberOfReader: item.numberOfReader,
        };
      });
    return dataArray ? data : [];
  };

  const onPageChange = (page, page_size) => {
    setPageIndex(page);
    setPageSize(page_size);
    onPageChangeAtSearch(page, page_size);
  };

  const onPageChangeAtSearch = async (indexPage, page_size) => {
    const params = {
      pageIndex: indexPage,
      pageSize: page_size,
      ...getFormSearch(),
    };

    const dataRes = await getAllData(params, indexPage);
    setListPost(dataRes);
  };

  const fetchData = async () => {
    const resListPost = await getAllData();
    const resListCategory = await getAllCategory();
    setListCategory(resListCategory.data);
    setListPost(resListPost);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = async () => {
    const dataRes = await getAllData({ pageIndex: 1 });
    setListPost(dataRes);
    setPageIndex(1);
    formSearch.resetFields();
  };

  const handleSearch = async () => {
    const params = {
      pageIndex: 1,
      pageSize: pageSize,
      ...getFormSearch(),
    };
    const dataRes = await getAllData(params, 1);

    setListPost(dataRes.length > 0 ? dataRes : false);
    setPageIndex(1);
  };

  const getFormSearch = () => {
    const dataForm = formSearch.getFieldsValue();

    return {
      title: dataForm.postName || "",
      category: dataForm.category || "",
    };
  };

  const onDelete = async (key) => {
    if (window.confirm("Bạn có chắc muốn xóa không?")) {
      const dataRes = await deletePosts(key);
      dataRes.status === 1
        ? message.success(`Xóa thành công!`)
        : message.error(`Xóa thất bại!`);
      const dataAll = await getAllData();

      setListPost(dataAll);
    }
  };

  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Slug",
      dataIndex: "slug",
    },
    {
      title: "Danh mục",
      dataIndex: "category",
      render: (_, record) => {
        if (!record.category) return "";
        const categoryName = !record.category.parent
          ? record.category.categoryName
          : record.category.categoryName +
            " (" +
            record.category.parent.categoryName +
            ")";
        return categoryName;
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (_, record) => {
        const listTagName = _?.map((item, index) => {
          return (
            <Tag color="default" key={index}>
              {item.tagName}
            </Tag>
          );
        });
        return listTagName;
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      render: (_, record) => {
        const strText = record.description;
        return strText.slice(0, 50) + "...";
      },
    },
    {
      title: "Ảnh",
      dataIndex: "thumb",
      render: (_, record) => {
        return (
          <Image width={150} src={`${process.env.REACT_APP_IMAGE_URL}/` + _} />
        );
      },
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      render: (_, record) => {
        return convertHtmlText(_);
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: (_, record) => {
        if (_ === 0)
          return (
            <Badge style={{ whiteSpace: "nowrap" }} color="red" text="Ẩn bài" />
          );
        if (_ === 1)
          return (
            <Badge
              style={{ whiteSpace: "nowrap" }}
              color="green"
              text="Hiện bài"
            />
          );
        if (_ === 2)
          return (
            <Badge
              style={{ whiteSpace: "nowrap" }}
              color="blue"
              text="Chờ duyệt"
            />
          );
      },
    },
    {
      title: "Số người đọc",
      dataIndex: "numberOfReader",
    },
    {
      title: "Ngày đăng",
      dataIndex: "createdTime",
      // render: (_, record) => (

      // )
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => (
        <Space>
          <Tooltip title="Sửa">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
              onClick={() => window.open(`/post/${record.key}`, "_blank")}
            />
          </Tooltip>
          <Tooltip title="Xóa">
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => onDelete(record.key)}
            />
          </Tooltip>
          {record.status === 1 && (
            <Tooltip title="Xem">
              <a
                href={process.env.REACT_APP_WEB_APP_URL + "/" + record.slug}
                target="_blank"
              >
                <Button
                  type="danger"
                  shape="circle"
                  icon={<FundViewOutlined />}
                  size="small"
                />
              </a>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Bài viết" pageTitle="Quản lí bài viết" />
          <Row>
            <Col xs={12}>
              <Form form={formSearch} layout="vertical" autoComplete="off">
                <Row>
                  <Col sm={4} hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item
                      name="postName"
                      label="Tiêu đề"
                      rules={[
                        {
                          required: false,
                          message: "Please input post name!",
                        },
                        {
                          type: "postName",
                        },
                        {
                          type: "string",
                          min: 1,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Nhập tên bài viết..."
                        name="postName"
                        allowClear={true}
                        onChange={(e) => setPostName(e.target.value)}
                        value={postName}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item name="category" label="Danh mục">
                      <Select
                        placeholder="Chọn một danh mục bài viết..."
                        allowClear
                        showSearch
                        name="category"
                        filterOption={(input, option) =>
                          option.children.includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {listCategory.length > 0 &&
                          listCategory.map((item) => {
                            return (
                              <Option key={item._id} value={item._id}>
                                {item.categoryName}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleSearch()}
                    >
                      Tìm kiếm
                    </Button>
                    <Link to={`/post`}>
                      <Button type="primary">Tạo mới</Button>
                    </Link>

                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                      Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <DataTable
              listData={listPost}
              pageSize={pageSize}
              columns={columns}
              indexPage={indexPage}
              totalPage={totalPage}
              onPageChange={onPageChange}
              loading={loading}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Posts;
